import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-breadcrumb',
  templateUrl: './custom-breadcrumb.component.html',
  styleUrls: ['./custom-breadcrumb.component.scss']
})
export class CustomBreadcrumbComponent implements OnInit {

  @Input()breadCrumb:Array<any>;

  constructor(private router:Router) { }

  ngOnInit(): void {
  }

  navigate({name:name,id:id}){
    switch (name) {
      case 'home':
        this.router.navigate(['/home'])
        break;
      case 'cat':
        if(id){
          this.router.navigate(['/home/products'],{queryParams:{category:id}})
        }
        break
      case 'subcat':
        if(id){
          this.router.navigate(['/home/products'],{queryParams:{category:id}})
        }
        break
      default:
        break;
    }

  }

}
