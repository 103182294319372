<div class="banners-container" fxLayout="column" fxLayout.gt-sm="row" *ngIf="banners.length > 0">

    <!-- if only one banner -->
    <div *ngIf="banners.length==1" class="borderRadius15"  fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="100">
        <div class="banner" fxFlex="100" fxFlex.gt-sm="100" [ngStyle]="getBgImage(0)">
            <div class="info" fxLayoutAlign="start start">
                <div class="px-2" fxLayout="column" fxLayoutAlign="center center">
                    <h1 class="title">{{getBanner(0).title}}</h1>
                    <h3 class="subtitle">{{getBanner(0).subtitle}}</h3>
                    <a  *ngIf="!getBanner(0).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(0))">Shop now</a>
                    <a *ngIf="getBanner(0).subcat" [matMenuTriggerFor]="menu" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                        Shop now 
                        <mat-icon>arrow_drop_down</mat-icon>
                    </a>
                    <mat-menu #menu="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                        <ng-container *ngFor="let cat of getBanner(0).subcat">
                            <a mat-menu-item   (click)="showProducts(getBanner(0))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                                {{cat.categoryName |slice:0:19 }}
                            </a>
                        </ng-container>
                    </mat-menu>
                </div> 
            </div> 
        </div>
    </div>

    <!-- if having two banner -->

    <div *ngIf="banners.length==2" fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="40">
        <div class="banner" fxFlex="100" fxFlex.gt-sm="100" [ngStyle]="getBgImage(0)">
            <div class="info" fxLayoutAlign="start start">
                <div class="px-2" fxLayout="column" fxLayoutAlign="center center">
                    <h1 class="title">{{getBanner(0).title}}</h1>
                    <h3 class="subtitle">{{getBanner(0).subtitle}}</h3>
                    <a  *ngIf="!getBanner(0).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(0))">Shop now</a>
                    <a *ngIf="getBanner(0).subcat" [matMenuTriggerFor]="menu" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                        Shop now 
                        <mat-icon>arrow_drop_down</mat-icon>
                    </a>
                    <mat-menu #menu="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                        <ng-container *ngFor="let cat of getBanner(0).subcat">
                            <a mat-menu-item   (click)="showProducts(getBanner(0))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                                {{cat.categoryName |slice:0:19 }}
                            </a>
                        </ng-container>
                    </mat-menu>
                    
                </div> 
            </div> 
        </div>
    </div>
    <div *ngIf="banners.length==2" fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="60">
        <div fxLayout="row" fxFlexOrder="2" fxFlexOrder.gt-sm="1" fxFlex="100" fxFlex.gt-sm="100">
            <div class="banner" fxFlex [ngStyle]="getBgImage(1)">
                <div class="info" fxLayoutAlign="space-around center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(1).title}}</h2>
                      <h4 class="subtitle">{{getBanner(1).subtitle}}</h4>
                      <a  *ngIf="!getBanner(1).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(1))">Shop now</a>
                      <a *ngIf="getBanner(1).subcat" [matMenuTriggerFor]="menu1" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                          Shop now 
                          <mat-icon>arrow_drop_down</mat-icon>
                      </a>
                      <mat-menu #menu1="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                          <ng-container *ngFor="let cat of getBanner(1).subcat">
                              <a mat-menu-item   (click)="showProducts(getBanner(1))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                                  {{cat.categoryName |slice:0:19 }}
                              </a>
                          </ng-container>
                      </mat-menu>
                      </div>                    
                </div>
            </div>
        </div>
    </div>

    <!-- if having 3 categories -->

    <div *ngIf="banners.length==3" fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="40">
        <div class="banner" fxFlex="50" fxFlex.gt-sm="67" [ngStyle]="getBgImage(0)">
            <div class="info" fxLayoutAlign="start start">
                <div class="px-2" fxLayout="column" fxLayoutAlign="center center">
                    <h1 class="title">{{getBanner(0).title}}</h1>
                    <h3 class="subtitle">{{getBanner(0).subtitle}}</h3>
                    <a  *ngIf="!getBanner(0).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(0))">Shop now</a>
                    <a *ngIf="getBanner(0).subcat" [matMenuTriggerFor]="menu" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                        Shop now 
                        <mat-icon>arrow_drop_down</mat-icon>
                    </a>
                    <mat-menu #menu="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                        <ng-container *ngFor="let cat of getBanner(0).subcat">
                            <a mat-menu-item   (click)="showProducts(getBanner(0))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                                {{cat.categoryName |slice:0:19 }}
                            </a>
                        </ng-container>
                    </mat-menu>
                </div> 
            </div> 
        </div>
        <div fxLayout="row" fxFlex="50" fxFlex.gt-sm="33">
            <div class="banner" fxFlex [ngStyle]="getBgImage(1)">
                <div class="info" fxLayoutAlign="center center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(1).title}}</h2>
                      <h4 class="subtitle">{{getBanner(1).subtitle}}</h4>
                      <a  *ngIf="!getBanner(1).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(1))">Shop now</a>
                      <a *ngIf="getBanner(1).subcat" [matMenuTriggerFor]="menu1" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                          Shop now 
                          <mat-icon>arrow_drop_down</mat-icon>
                      </a>
                      <mat-menu #menu1="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                          <ng-container *ngFor="let cat of getBanner(1).subcat">
                              <a mat-menu-item   (click)="showProducts(getBanner(1))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                                  {{cat.categoryName |slice:0:19 }}
                              </a>
                          </ng-container>
                      </mat-menu>
                      </div> 
                </div> 
            </div>
        </div>
    </div>
    <div *ngIf="banners.length==3" fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="60">
        <div fxLayout="row" fxFlexOrder="2" fxFlexOrder.gt-sm="1" fxFlex="100" fxFlex.gt-sm="100">
            <div class="banner" fxFlex [ngStyle]="getBgImage(2)">
                <div class="info" fxLayoutAlign="space-around center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(2).title}}</h2>
                      <h4 class="subtitle">{{getBanner(2).subtitle}}</h4>
                      <a  *ngIf="!getBanner(2).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(2))">Shop now</a>
                      <a *ngIf="getBanner(2).subcat" [matMenuTriggerFor]="menu2" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                          Shop now 
                          <mat-icon>arrow_drop_down</mat-icon>
                      </a>
                      <mat-menu #menu2="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                          <ng-container *ngFor="let cat of getBanner(2).subcat">
                              <a mat-menu-item   (click)="showProducts(getBanner(2))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                                  {{cat.categoryName |slice:0:19 }}
                              </a>
                          </ng-container>
                      </mat-menu>
                      </div>                    
                </div>
            </div>
        </div>
    </div>

    <!-- if having 4 banners -->

    <div *ngIf="banners.length==4" fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="40">
        <div class="banner" fxFlex="50" fxFlex.gt-sm="67" [ngStyle]="getBgImage(0)">
            <div class="info" fxLayoutAlign="start start">
                <div class="px-2" fxLayout="column" fxLayoutAlign="center center">
                    <h1 class="title">{{getBanner(0).title}}</h1>
                    <h3 class="subtitle">{{getBanner(0).subtitle}}</h3>
                    <a  *ngIf="!getBanner(0).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(0))">Shop now</a>
                    <a *ngIf="getBanner(0).subcat" [matMenuTriggerFor]="menu" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                        Shop now 
                        <mat-icon>arrow_drop_down</mat-icon>
                    </a>
                    <mat-menu #menu="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                        <ng-container *ngFor="let cat of getBanner(0).subcat">
                            <a mat-menu-item   (click)="showProducts(getBanner(0))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                                {{cat.categoryName |slice:0:19 }}
                            </a>
                        </ng-container>
                    </mat-menu>
                </div> 
            </div> 
        </div>
        <div fxLayout="row" fxFlex="50" fxFlex.gt-sm="33">
            <div class="banner" fxFlex [ngStyle]="getBgImage(1)">
                <div class="info" fxLayoutAlign="center center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(1).title}}</h2>
                      <h4 class="subtitle">{{getBanner(1).subtitle}}</h4>
                      <a  *ngIf="!getBanner(1).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(1))">Shop now</a>
                      <a *ngIf="getBanner(1).subcat" [matMenuTriggerFor]="menu1" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                          Shop now 
                          <mat-icon>arrow_drop_down</mat-icon>
                      </a>
                      <mat-menu #menu1="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                          <ng-container *ngFor="let cat of getBanner(1).subcat">
                              <a mat-menu-item   (click)="showProducts(getBanner(1))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                                  {{cat.categoryName |slice:0:19 }}
                              </a>
                          </ng-container>
                      </mat-menu>
                      </div> 
                </div> 
            </div>
           
        </div>
    </div>
    <div *ngIf="banners.length==4" fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="60">
        <div fxLayout="row" fxFlexOrder="2" fxFlexOrder.gt-sm="1" fxFlex="50" fxFlex.gt-sm="33">
            <div class="banner" fxFlex [ngStyle]="getBgImage(2)">
                <div class="info" fxLayoutAlign="space-around center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(2).title}}</h2>
                      <h4 class="subtitle">{{getBanner(2).subtitle}}</h4>
                      <a  *ngIf="!getBanner(2).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(2))">Shop now</a>
                      <a *ngIf="getBanner(2).subcat" [matMenuTriggerFor]="menu2" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                          Shop now 
                          <mat-icon>arrow_drop_down</mat-icon>
                      </a>
                      <mat-menu #menu2="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                          <ng-container *ngFor="let cat of getBanner(2).subcat">
                              <a mat-menu-item   (click)="showProducts(getBanner(2))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                                  {{cat.categoryName |slice:0:19 }}
                              </a>
                          </ng-container>
                      </mat-menu>
                      </div>                    
                </div>
            </div>
        </div>
        <div class="banner" fxFlexOrder="1" fxFlexOrder.gt-sm="2" fxFlex="50" fxFlex.gt-sm="67" [ngStyle]="getBgImage(3)">
            <div class="info" fxLayout="column" fxLayoutAlign="center end">
                <div class="px-2" fxLayout="column" fxLayoutAlign="center center">
                  <h1 class="title">{{getBanner(3).title}}</h1>
                  <h3 class="subtitle">{{getBanner(3).subtitle}}</h3>
                  <a  *ngIf="!getBanner(3).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(3))">Shop now</a>
                  <a *ngIf="getBanner(3).subcat" [matMenuTriggerFor]="menu3" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                      Shop now 
                      <mat-icon>arrow_drop_down</mat-icon>
                  </a>
                  <mat-menu #menu3="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                      <ng-container *ngFor="let cat of getBanner(3).subcat">
                          <a mat-menu-item   (click)="showProducts(getBanner(3))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                              {{cat.categoryName |slice:0:19 }}
                          </a>
                      </ng-container>
                  </mat-menu>
              </div>
            </div> 
        </div>
    </div>

     <!-- if having 5 banners -->

     <div *ngIf="banners.length==5" fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="40">
        <div class="banner" fxFlex="50" fxFlex.gt-sm="67" [ngStyle]="getBgImage(0)">
            <div class="info" fxLayoutAlign="start start">
                <div class="px-2" fxLayout="column" fxLayoutAlign="center center">
                    <h1 class="title">{{getBanner(0).title}}</h1>
                    <h3 class="subtitle">{{getBanner(0).subtitle}}</h3>
                    <a  *ngIf="!getBanner(0).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(0))">Shop now</a>
                    <a *ngIf="getBanner(0).subcat" [matMenuTriggerFor]="menu" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                        Shop now 
                        <mat-icon>arrow_drop_down</mat-icon>
                    </a>
                    <mat-menu #menu="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                        <ng-container *ngFor="let cat of getBanner(0).subcat">
                            <a mat-menu-item   (click)="showProducts(getBanner(0))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                                {{cat.categoryName |slice:0:19 }}
                            </a>
                        </ng-container>
                    </mat-menu>
                </div> 
            </div> 
        </div>
        <div fxLayout="row" fxFlex="50" fxFlex.gt-sm="33">
            <div class="banner" fxFlex [ngStyle]="getBgImage(1)">
                <div class="info" fxLayoutAlign="center center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(1).title}}</h2>
                      <h4 class="subtitle">{{getBanner(1).subtitle}}</h4>
                      <a  *ngIf="!getBanner(1).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(1))">Shop now</a>
                      <a *ngIf="getBanner(1).subcat" [matMenuTriggerFor]="menu1" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                          Shop now 
                          <mat-icon>arrow_drop_down</mat-icon>
                      </a>
                      <mat-menu #menu1="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                          <ng-container *ngFor="let cat of getBanner(1).subcat">
                              <a mat-menu-item   (click)="showProducts(getBanner(1))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                                  {{cat.categoryName |slice:0:19 }}
                              </a>
                          </ng-container>
                      </mat-menu>
                      </div> 
                </div> 
            </div>
            <div class="banner" fxFlex [ngStyle]="getBgImage(2)">
                <div class="info" fxFlex fxLayoutAlign="center center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(2).title}}</h2>
                      <h4 class="subtitle">{{getBanner(2).subtitle}}</h4>
                      <a  *ngIf="!getBanner(2).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(2))">Shop now</a>
                      <a *ngIf="getBanner(2).subcat" [matMenuTriggerFor]="menu2" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                          Shop now 
                          <mat-icon>arrow_drop_down</mat-icon>
                      </a>
                      <mat-menu #menu2="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                          <ng-container *ngFor="let cat of getBanner(2).subcat">
                              <a mat-menu-item   (click)="showProducts(getBanner(2))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                                  {{cat.categoryName |slice:0:19 }}
                              </a>
                          </ng-container>
                      </mat-menu>
                      </div> 
                </div> 
            </div>
           
        </div>
    </div>
    <div *ngIf="banners.length==5" fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="60">
        <div fxLayout="row" fxFlexOrder="2" fxFlexOrder.gt-sm="1" fxFlex="50" fxFlex.gt-sm="33">
            <div class="banner" fxFlex [ngStyle]="getBgImage(3)">
                <div class="info" fxLayoutAlign="space-around center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(3).title}}</h2>
                      <h4 class="subtitle">{{getBanner(3).subtitle}}</h4>
                      <a  *ngIf="!getBanner(3).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(3))">Shop now</a>
                      <a *ngIf="getBanner(3).subcat" [matMenuTriggerFor]="menu3" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                          Shop now 
                          <mat-icon>arrow_drop_down</mat-icon>
                      </a>
                      <mat-menu #menu3="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                          <ng-container *ngFor="let cat of getBanner(3).subcat">
                              <a mat-menu-item   (click)="showProducts(getBanner(3))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                                  {{cat.categoryName |slice:0:19 }}
                              </a>
                          </ng-container>
                      </mat-menu>
                      </div>                    
                </div>
            </div>
        </div>
        <div class="banner" fxFlexOrder="1" fxFlexOrder.gt-sm="2" fxFlex="50" fxFlex.gt-sm="67" [ngStyle]="getBgImage(3)">
            <div class="info" fxLayout="column" fxLayoutAlign="center end">
                <div class="px-2" fxLayout="column" fxLayoutAlign="center center">
                  <h1 class="title">{{getBanner(4).title}}</h1>
                  <h3 class="subtitle">{{getBanner(4).subtitle}}</h3>
                  <a  *ngIf="!getBanner(4).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(4))">Shop now</a>
                  <a *ngIf="getBanner(4).subcat" [matMenuTriggerFor]="menu4" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                      Shop now 
                      <mat-icon>arrow_drop_down</mat-icon>
                  </a>
                  <mat-menu #menu4="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                      <ng-container *ngFor="let cat of getBanner(4).subcat">
                          <a mat-menu-item   (click)="showProducts(getBanner(4))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                              {{cat.categoryName |slice:0:19 }}
                          </a>
                      </ng-container>
                  </mat-menu>
              </div>
            </div> 
        </div>
    </div>

    <!-- if having 6 or more banners -->

    <div *ngIf="banners.length>=6" fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="40">
        <div class="banner" fxFlex="50" fxFlex.gt-sm="67" [ngStyle]="getBgImage(0)">
            <div class="info" fxLayoutAlign="start start">
                <div class="px-2" fxLayout="column" fxLayoutAlign="center center">
                    <h1 class="title">{{getBanner(0).title}}</h1>
                    <h3 class="subtitle">{{getBanner(0).subtitle}}</h3>
                    <a  *ngIf="!getBanner(0).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(0))">Shop now</a>
                    <a *ngIf="getBanner(0).subcat" [matMenuTriggerFor]="menu" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                        Shop now 
                        <mat-icon>arrow_drop_down</mat-icon>
                    </a>
                    <mat-menu #menu="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                        <ng-container *ngFor="let cat of getBanner(0).subcat">
                            <a mat-menu-item   (click)="showProducts(getBanner(0))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                                {{cat.categoryName |slice:0:19 }}
                            </a>
                        </ng-container>
                    </mat-menu>
                </div> 
            </div> 
        </div>
        <div fxLayout="row" fxFlex="50" fxFlex.gt-sm="33">
            <div class="banner" fxFlex [ngStyle]="getBgImage(1)">
                <div class="info" fxLayoutAlign="center center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(1).title}}</h2>
                      <h4 class="subtitle">{{getBanner(1).subtitle}}</h4>
                      <a  *ngIf="!getBanner(1).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(1))">Shop now</a>
                      <a *ngIf="getBanner(1).subcat" [matMenuTriggerFor]="menu1" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                        Shop now 
                        <mat-icon>arrow_drop_down</mat-icon>
                    </a>
                    <mat-menu #menu1="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                        <ng-container *ngFor="let cat of getBanner(1).subcat">
                            <a mat-menu-item   (click)="showProducts(getBanner(1))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                                {{cat.categoryName |slice:0:19 }}
                            </a>
                        </ng-container>
                    </mat-menu>
                    </div> 
                </div> 
            </div>
            <div class="banner" fxFlex [ngStyle]="getBgImage(2)">
                <div class="info" fxFlex fxLayoutAlign="center center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(2).title}}</h2>
                      <h4 class="subtitle">{{getBanner(2).subtitle}}</h4>
                      <a  *ngIf="!getBanner(2).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(2))">Shop now</a>
                      <a *ngIf="getBanner(2).subcat" [matMenuTriggerFor]="menu2" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                        Shop now 
                        <mat-icon>arrow_drop_down</mat-icon>
                    </a>
                    <mat-menu #menu2="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                        <ng-container *ngFor="let cat of getBanner(2).subcat">
                            <a mat-menu-item   (click)="showProducts(getBanner(2))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                                {{cat.categoryName |slice:0:19 }}
                            </a>
                        </ng-container>
                    </mat-menu>                    
                </div> 
                </div> 
            </div>
        </div>
    </div>
    <div *ngIf="banners.length>=6"  fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="60">
        <div fxLayout="row" fxFlexOrder="2" fxFlexOrder.gt-sm="1" fxFlex="50" fxFlex.gt-sm="33">
            <div class="banner" fxFlex [ngStyle]="getBgImage(3)">
                <div class="info" fxLayoutAlign="space-around center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(3).title}}</h2>
                      <h4 class="subtitle">{{getBanner(3).subtitle}}</h4>
                      <a  *ngIf="!getBanner(3).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(3))">Shop now</a>
                      <a *ngIf="getBanner(3).subcat" [matMenuTriggerFor]="menu3" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                        Shop now 
                        <mat-icon>arrow_drop_down</mat-icon>
                    </a>
                    <mat-menu #menu3="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                        <ng-container *ngFor="let cat of getBanner(3).subcat">
                            <a mat-menu-item   (click)="showProducts(getBanner(3))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                                {{cat.categoryName |slice:0:19 }}
                            </a>
                        </ng-container>
                    </mat-menu>                   
                 </div>                    
                </div>
            </div>
            <div class="banner" fxFlex [ngStyle]="getBgImage(4)">
                <div class="info" fxLayoutAlign="center center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
                      <h2 class="title">{{getBanner(4).title}}</h2>
                      <h4 class="subtitle">{{getBanner(4).subtitle}}</h4>
                      <a  *ngIf="!getBanner(4).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(4))">Shop now</a>
                      <a *ngIf="getBanner(4).subcat" [matMenuTriggerFor]="menu4" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                        Shop now 
                        <mat-icon>arrow_drop_down</mat-icon>
                    </a>
                    <mat-menu #menu4="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                        <ng-container *ngFor="let cat of getBanner(4).subcat">
                            <a mat-menu-item   (click)="showProducts(getBanner(4))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                                {{cat.categoryName |slice:0:19 }}
                            </a>
                        </ng-container>
                    </mat-menu>                   
                 </div>
                </div> 
            </div>
        </div>
        <div class="banner" fxFlexOrder="1" fxFlexOrder.gt-sm="2" fxFlex="50" fxFlex.gt-sm="67" [ngStyle]="getBgImage(5)">
            <div class="info" fxLayout="column" fxLayoutAlign="center end">
                <div class="px-2" fxLayout="column" fxLayoutAlign="center center">
                  <h1 class="title">{{getBanner(5).title}}</h1>
                  <h3 class="subtitle">{{getBanner(5).subtitle}}</h3>
                  <a  *ngIf="!getBanner(5).subcat" mat-raised-button color="primary" class="mt-2" (click)="showProducts(getBanner(5))">Shop now</a>
                  <a *ngIf="getBanner(5).subcat" [matMenuTriggerFor]="menu5" color="primary" mat-raised-button class="mt-2 control-btn"  (click)="openMegaMenu()">
                    Shop now 
                    <mat-icon>arrow_drop_down</mat-icon>
                </a>
                <mat-menu #menu5="matMenu" [overlapTrigger]="false" class="sub-cat-menu app-dropdown">
                    <ng-container *ngFor="let cat of getBanner(5).subcat">
                        <a mat-menu-item   (click)="showProducts(getBanner(5))"  [value]="cat.categoryName |slice:0:19" style="cursor: pointer; text-align: left !important; min-width: 150px; max-width: 400px;">
                            {{cat.categoryName |slice:0:19 }}
                        </a>
                    </ng-container>
                </mat-menu>                
            </div>
                
            </div> 
          
        </div>
        <div *ngIf="banners.length>6" class="showmorelink"  fxFlexOrder="1">
            <a mat-raised-button style="width:100%;" color="primary" [routerLink]="['/home/category-list']" routerLinkActive="router-link-active" >Show More Categories</a>
        </div>
       
    </div>
    
    

</div>