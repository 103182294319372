<div fxLayout="row wrap" >
    <div fxFlex="100">
        <mat-card fxLayout="row" class="breadcrumb light-block">

            <mat-icon>table_chart</mat-icon>
          
            <div  class="breadcrumb-item cursor" fxLayout="row" fxLayoutAlign="start center">                 
                <a (click)="navigate({name:'home',id:null})">Home</a>
                <mat-icon >arrow_right</mat-icon>
               
            </div> 
            <div  class="breadcrumb-item cursor" fxLayout="row" fxLayoutAlign="start center" *ngFor="let item of breadCrumb;let i=index;">                 
                <a (click)="navigate({name:'cat',id:item.subcat[0].id})">{{item.name}}</a>
                <mat-icon [ngClass]="{'hide': i+1==breadCrumb.length}" >arrow_right</mat-icon>
                <a *ngFor="let subcat of item.subcat" (click)="navigate({name:'subcat',id:subcat.id})">
                    &nbsp;/&nbsp;{{subcat.name}}
                </a>
            </div> 
        </mat-card>
    </div> 
</div>
