import { Component, OnInit } from '@angular/core';
import { UserDataModel} from '../interfaces/login';
import { WareHouseAddress} from '../interfaces/address'
import { LoginService } from '../../sign-in/login.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public lat: number = 40.678178;
  public lng: number = -73.944158;
  public zoom: number = 12;

  facebookUrl:string="";
  instagramUrl:string="";
  linkedinUrl:string="";
  twitterUrl:string="";
  youtubeUrl:string="";
  aboutUs:string="";
  logo:string="";
  warehouseName:string="";
  userDetails:UserDataModel = null;
  shopAddress : WareHouseAddress;
  openShopToken:string="";
  social:boolean = false;
  openShop: any;
  // defaultFooter:boolean=false;

  constructor(private router:Router,
    private route:ActivatedRoute,private http: HttpClient, private loginService:LoginService,public snackBar: MatSnackBar) {
    // this.userDetails = this.loginService.getLocalUser();
    this.userDetails = JSON.parse(sessionStorage.getItem('userData'))
   }

  ngOnInit() {
    this.openShop=JSON.parse(sessionStorage.getItem('openShop'))
    if(this.userDetails){
      this.footerinit()
    }
   }

  /**
   * Initializing Footer details
   */
  footerinit() {
    this.loginService.getFooterDetails(this.userDetails).subscribe((data:any)=>{

      if(data){
        this.social=true
        if(data.facebookUrl!=null){
          this.facebookUrl = data.facebookUrl;
        }
        if(data.linkedinUrl!=null){
          this.linkedinUrl = data.linkedinUrl
        }
        if(data.twitterUrl!=null){
          this.twitterUrl = data.twitterUrl;
        }
        if(data.youtubeUrl!=null){
          this.youtubeUrl= data.youtubeUrl;
        }
        if(data.instagramUrl!=null){
          this.instagramUrl= data.instagramUrl;
        }
        if(data.aboutUs !=null){
          this.aboutUs=data.aboutUs;
        }

      }
    })
    if (this.userDetails) {
      this.logo = this.userDetails.warehouses[0].warehouseLogo
      this.warehouseName = this.userDetails.warehouses[0].warehouseName
      this.shopAddress = {
        address: this.userDetails.warehouses[0].address.adLine1 + ' ' +
        (this.userDetails.warehouses[0].address.adLine2 != null ? this.userDetails.warehouses[0].address.adLine2 : " ")
        + ' ' + (this.userDetails.warehouses[0].address.street !== null ? this.userDetails.warehouses[0].address.street : "")
        + ',' + (this.userDetails.warehouses[0].address.city != null ? this.userDetails.warehouses[0].address.city : "") + ','
        + (this.userDetails.warehouses[0].address.state != null ? this.userDetails.warehouses[0].address.state : "") + ','
        + (this.userDetails.warehouses[0].address.country != null ? this.userDetails.warehouses[0].address.country : ""),
        email: this.userDetails.warehouses[0].address.email,
        phone: this.userDetails.warehouses[0].address.phone
      }
    }
  }
    
  goToOffers(){
    if(this.openShop!=true){
      window.scrollTo(0, 2125);
    }
    else{
      window.scroll(0, 1360)
    }
   }
   
  subscribe(){ }

}


