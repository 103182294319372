<div class="brands-carousel"> 
    <div class="swiper-container h-100" [swiper]="config">
        <!--<a mat-button routerLink="/brands">Show All Brands</a> -->
        <div class="swiper-wrapper h-100">      
            <div *ngFor="let brand of brands" class="swiper-slide">
                <div class="p-0">
                    <a [routerLink]="['/brands', brand.name]" class="brand-item">
                        <img [attr.data-src]="brand.image" class="swiper-lazy">
                    </a>
                    <div class="swiper-lazy-preloader"></div>
                </div>
            </div>
        </div> 
        
    </div>
</div>