import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { LoginService } from '../sign-in/login.service'
import { GlobalStateService } from '../shared/service/global-state.service';

@Injectable()
export class AppInterceptors implements HttpInterceptor {
  constructor(private authService: LoginService, public router: Router,private GdlobalStateService:GlobalStateService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("AuthorizationToken");

    const catalogToken = localStorage.getItem("catalogToken");

    if (req.url.endsWith('/openshop')) {

      req = req.clone({ headers: req.headers.set('Authorization', token) });
    }

    else if (catalogToken && req.url.endsWith('/userdetails')) {
      req = req.clone({ headers: req.headers.set('Authorization', catalogToken) });
    }

    else if (token && !req.url.endsWith('setpassword')) {
      req = req.clone({ headers: req.headers.set('Authorization', token) });
    }

    if (!req.headers.has('Content-Type')) {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    }

    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        const tempToken = event.headers.get('Authorization')
        if (tempToken && tempToken.length) {
          this.authService.updateToken(tempToken)
        }
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.GdlobalStateService.updateGlobalData({userLogged:false})
          this.router.navigate(['/sign-in'])
        }
      }
    }))


  }

}
