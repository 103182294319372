<mat-toolbar id="menu-toolbar" class="top-navbar mat-elevation-z2" fxLayoutAlign="center center">
    <a mat-button routerLink="/home" [ngClass]="{'horizontal-active-link': activeroute=='home'}" (click)="activeroute='home'">{{ 'NAV.HOME' | translate }}</a>
    <a mat-button  routerLink="/home/contact"  [ngClass]="{'horizontal-active-link': activeroute=='aboutus'}" (click)="activeroute='aboutus'">About Us</a>

    <a mat-button [matMenuTriggerFor]="menu"  [ngClass]="{'horizontal-active-link': activeroute=='products'}"  (click)="openMegaMenu();" >PRODUCTS<mat-icon class="caret">arrow_drop_down</mat-icon></a>


    <mat-menu #menu="matMenu" [overlapTrigger]="false" class="mega-menu app-dropdown">
        <div fxLayout="row wrap" fxLayoutAlign="center">
            <div fxFlex="20" fxLayout="column"  class="p-1" *ngFor="let category of categories | slice:0:4">
                <a mat-menu-item routerLink="/"  (click)="NavigateToCategory(category?.category?.length>0?category.categoryId:category.categoryId);activeroute='products'" style="color: #006600 !important;"><b>{{category.categoryName}}</b></a>
                <mat-divider></mat-divider>
                <ng-container *ngFor="let subcat of category.category">
                    <a mat-menu-item routerLink="/"  class="text-muted" style="color: #1133aa !important;"  (click)="NavigateToCategory(subcat.categoryId);activeroute='products'"  *ngIf=" category.category">{{subcat.categoryName}}</a>
                </ng-container>
                <ng-container *ngFor="let product of category?.products?.product">
                  <a mat-menu-item routerLink="/"  class="text-muted" style="color: #1133aa !important;"  (click)="navigateProductDetail(product.productId,product.productShortName,product.sku[0].skuId);activeroute='products'" *ngIf="!category.category && product.sku[0]?.skuId">{{product.productShortName}}</a>
              </ng-container>
            </div>

            <div fxFlex="20" *ngIf="categories.length>4" fxLayout="column" class="p-1">
                <button mat-menu-item (click)="NavigateToCategory();activeroute='products'" >
                    <b style="color: blueviolet;">Show All Categories</b>
                </button>
                <mat-divider></mat-divider>
            </div>
            <!-- <div fxFlex="20" class="p-1">
                <mat-card class="mega-menu-widget p-0">
                    <mat-chip-list>
                        <mat-chip color="warn" selected="true">50% OFF</mat-chip>
                    </mat-chip-list>
                    <button mat-raised-button color="primary">Shop now</button>
                    <img src="assets/images/others/mega_menu_img.png" alt="" class="w-100">
                </mat-card>
            </div> -->
        </div>

    </mat-menu>
    <a mat-button [matMenuTriggerFor]="accountMenu" (click)="openMegaMenu()" *ngIf="userLogged" [ngClass]="{'horizontal-active-link': activeroute=='myaccount'}">My Account<mat-icon class="caret">arrow_drop_down</mat-icon></a>
    <mat-menu #accountMenu="matMenu" [overlapTrigger]="false" class="mega-menu app-dropdown">
      <div fxLayout="row wrap" fxLayoutAlign="center">
          <div fxFlex="50" fxLayout="row"  class="p-1" fxLayoutAlign="center" >
              <a mat-menu-item routerLink="/home/account/information" style="color: #616161 !important;" (click)="activeroute='myaccount'"><b>User Profile</b></a>
              <a mat-menu-item routerLink="/home/account/orders" style="color: #616161  !important;" (click)="activeroute='myaccount'"><b>Order History</b></a>
              <a mat-menu-item routerLink="/home/wishlist" style="color: #616161  !important;" (click)="activeroute='myaccount'"><b>Wishlist</b></a>
              <a mat-menu-item (click)="logOut();activeroute='myaccount'" style="color: #921b27 !important;"><b>Logout</b></a>
              <mat-divider></mat-divider>
          </div>
      </div>

  </mat-menu>

    <a mat-button routerLink="." (click)="goToOffers()">OFFERS</a>

    <!-- <a mat-button routerLink="/contact" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'NAV.CONTACT' | translate }}</a> -->
     <a mat-button  routerLink="/home/faqs" (click)="activeroute='faq'" [ngClass]="{'horizontal-active-link': activeroute=='faq'}" >FAQs</a>

</mat-toolbar>

