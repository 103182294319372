<div fxLayout="row wrap" *ngIf="router.url != '/home'">
    <div fxFlex="100">
        <mat-card fxLayout="row" class="breadcrumb light-block">

            <mat-icon>table_chart</mat-icon>
            <div *ngFor="let breadcrumb of breadcrumbs; let i = index;" class="breadcrumb-item" fxLayout="row" fxLayoutAlign="start center">                 
                <a [hidden]="i == (breadcrumbs.length - 1)" [routerLink]="[breadcrumb.url]">{{breadcrumb.name}}</a>   
                <span [hidden]="i != (breadcrumbs.length - 1)" class="breadcrumb-title active"><b>{{breadcrumb.name}}</b></span>
            </div> 
        </mat-card>
    </div> 
</div>

