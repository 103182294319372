
import { Component, Inject, OnInit } from '@angular/core';
import { LoginService } from '../sign-in/login.service'
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { GlobalStateService } from '../shared/service/global-state.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-openshop',
  templateUrl: './openshop.component.html',
  styleUrls: ['./openshop.component.css']
})
export class OpenshopComponent implements OnInit {

  siteUrl: string;
  blckUrl: string = environment.blckUrl;

  constructor(
    private router: Router,
    private loginService: LoginService,
    public snackBar: MatSnackBar,
    private GlobalStateService:GlobalStateService,
    private spinner: NgxSpinnerService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.authValidate()
  }

  /**
   * Validate openshop url, if openshop is true navigate to home, if false navigate to login component
   * important: always update globalstate value once we got response from BE
   */
  authValidate() {
    this.spinner.show();
    this.siteUrl = window.location.href
    if(this.GlobalStateService.globalData?.userLogged){
      // this.document.getElementById('appFavicon').setAttribute('href', this.GlobalStateService.globalData?.status?.warehouses[0].warehouseLogo);
      console.log("if",this.GlobalStateService.globalData?.status?.warehouses[0].warehouseLogo)
      this.router.navigate(['/home'])
      this.spinner.hide();
    }
    else{
      if (this.siteUrl) {
        this.loginService.checkDomainName(this.siteUrl).subscribe(
          {
            next:(response)=>{
              this.GlobalStateService.updateGlobalData({openShop:response.isOpenShop})
              if(response.isOpenShop == true){
                this.loginService.checkOpenShop().subscribe({
                  next:(checkResponse)=>{
                    // this.GlobalStateService.updateGlobalData(checkResponse)
                    if(!this.GlobalStateService.globalData?.userLogged){
                      this.GlobalStateService.updateGlobalData(checkResponse)
                      // this.document.getElementById('appFavicon').setAttribute('href', this.GlobalStateService.globalData?.status?.warehouses[0].warehouseLogo);
                      console.log("else",this.GlobalStateService.globalData?.status?.warehouses[0].warehouseLogo)

                    }
                    if(checkResponse.status.status=='SUCCESS'){
                      sessionStorage.setItem('openShop', 'true');
                      this.spinner.hide();
                      this.router.navigate(['/home']);
                    }
                    else{
                      this.router.navigate(['/sign-in'])
                      this.spinner.hide();
                    }
                  }
                })
              }
              else{
                this.router.navigate(['/sign-in'])
                this.spinner.hide();
              }
            },
            error:(err)=>{
              console.log(err)
              this.spinner.hide();
              this.snackBar.open('Something went wrong! please contact support team for more support','Close',{ panelClass: 'error', verticalPosition: 'top', duration: 3000 })
            }
          })
      }
    }

  }
}
