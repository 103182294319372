import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CommonServices } from 'src/app/services/common.service';
import { CartItems, item } from 'src/app/services/models/cartitems.model';
import { MessageService } from '../../theme/utils/messageService';
import { GlobalStateService } from '../../shared/service/global-state.service';

@Component({
  selector: 'app-cart-dialog',
  templateUrl: './cart-dialog.component.html',
  styleUrls: ['./cart-dialog.component.css']
})
export class CartDialogComponent implements OnInit {

  totalPrice:number;
  items: item[];
  loading: boolean;
  subtotal:number = 0;
  openShop: boolean = false;
  displayImage: boolean;
  globalState: any;
  cartItem: any;

  constructor(
    private commonService:CommonServices,
    public snackBar:MatSnackBar,
    private router:Router,
    public dialogRef: MatDialogRef<CartDialogComponent>,
    private messageService: MessageService,
    private GlobalState:GlobalStateService
    ) {
    let User = JSON.parse(sessionStorage.getItem('userData'))
    this.displayImage = User?.warehouses[0].displayProdImages

}

  ngOnInit(): void {
    this.openShop=JSON.parse(sessionStorage.getItem('openShop'))
    this.globalState = this.GlobalState.globalData

    this.getCartItems()
  }

  // getTotalPrice(){
  //   return this.items.reduce((n,{price[0]})=>n+parseInt(price),0);
  //  }

  getCartItems(){
    // if(sessionStorage.getItem('cartdetails')){
    //   this.items = JSON.parse(sessionStorage.getItem('cartdetails')).order[0].items.item;
    // // }
    // this.items = this.globalState?.cartdetails
    // console.log('items',this.items)
    // this.GlobalState.updateGlobalData({'cartdetails': this.items})

    // else{

      this.loading = true;
    this.commonService.getCartDetails().subscribe({
      next:(response:CartItems)=>{
        if (response)
        this.items = response.order[0]?.items?.item;
        if(response.order.length>0){
          this.GlobalState.updateGlobalData({'cartdetails': response})
        }


        this.subtotal = this.items?.map(e=>{
          return e.price.offer?e.price.offer*e.quantity:e.quantity* e.price.actual
        }).reduce((a,b)=>a+b,0)
      },
      error:(err)=>{
        this.snackBar.open('Some thing went wrong!','',{duration:3000})
        this.loading = false;
        console.log(err)
      },
      complete:()=>{
        this.loading = false
      }
    })
    // }
  }

  removeProduct(id:any){
    this.commonService.removeProductFromCart(id).subscribe({
      next:(response)=>{
        if(response.order[0].header.status.value === 'CART' || response.order[0].header.status.value === 'OCART'){
          this.getCartItems();
          if(response.order.length>0){
            this.GlobalState.updateGlobalData({'cartdetails': response})
            this.messageService.sendMessage(JSON.stringify({cartdetails:response}))
          }

        }
        else{
          this.snackBar.open(response.order[0].header.status.message,'',{duration:3000})
        }

      },
      error:(err)=>{
        this.snackBar.open('Some thing went wrong!','',{duration:3000})
        console.log(err)
      }
    })
  }

  NavigateToCart(){
    this.dialogRef.close()
    this.router.navigate(['/home/cart'])
  }
  NavigateToCheckout(){
    this.dialogRef.close()
    this.router.navigate(['/home/checkout'])
  }

  navigateProductDetail(id:number,name:string,skuId:string){
    console.log("here")
    this.router.navigate(['/home/products',id,name],{queryParams:{sku:skuId,id:id}})
  }
}
