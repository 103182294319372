import { Component, OnInit, Input} from '@angular/core';
@Component({
  selector: 'app-main-carousel',
  templateUrl: './main-carousel.component.html',
  styleUrls: ['./main-carousel.component.scss']
})
export class MainCarouselComponent implements OnInit {
  @Input('slides') slides: Array<any> = [];
  @Input('loading') loading: Array<any> = [];

  constructor() { }
  slideIndex = 0;


  hidePreloader(element: HTMLElement) {
    element.style.display = "none"
  }

  ngOnInit() {
  }

  plusSlides(n) {
    if (n == 1) {
      let add = this.slideIndex + n
      if (add < this.slides.length) {
        this.slideIndex = add
      }
    }
    else {
      let minus = this.slideIndex + n
      if (minus >= 0) {
        this.slideIndex = minus
      }
    }
  }

  currentSlide(n) {
    this.slideIndex = n
  }

}