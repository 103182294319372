<mat-sidenav-container  >
    <mat-sidenav  [opened]="false" mode="over" #sidenav class="sidenav mat-elevation-z6">
        <button mat-icon-button color="warn" class="close" (click)="sidenav.close()">
           <mat-icon color="warn">close</mat-icon>
        </button>
        <div class="divider"></div>
        <app-sidenav-menu [menuItems]="sidenavMenuItems" [menuParentId]="0"></app-sidenav-menu>
        <div class="divider"></div>
        <div class="text-center py-2 sidebarnav">
            <div class="nav_icons">
                <a href="/#/home/wishlist">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.93895 12.6589C4.74987 18.2931 11.9044 22.8488 13.8057 23.9765C15.7135 22.8372 22.9197 18.2311 24.6725 12.664C25.8234 9.06546 24.7552 4.50717 20.5108 3.13929C18.4545 2.47925 16.0558 2.88096 14.3999 4.16229C14.0537 4.42838 13.5732 4.43354 13.2245 4.17004C11.4704 2.85125 9.17899 2.46504 7.09037 3.13929C2.85241 4.50588 1.78808 9.06417 2.93895 12.6589ZM13.807 26.0638C13.6469 26.0638 13.488 26.025 13.3433 25.9462C12.939 25.7254 3.41558 20.476 1.09445 13.2505C1.09316 13.2505 1.09316 13.2492 1.09316 13.2492C-0.36384 8.69992 1.25849 2.983 6.4962 1.29479C8.95553 0.499125 11.6357 0.849167 13.8032 2.21704C15.9034 0.889208 18.6934 0.522375 21.1037 1.29479C26.3465 2.98558 27.974 8.70121 26.5183 13.2492C24.2721 20.3921 14.6789 25.7202 14.272 25.9437C14.1274 26.0237 13.9672 26.0638 13.807 26.0638Z" fill="#3D3B36"/>
                    </svg>
                </a>
            </div>

            <div class="nav_icons">
                <a href="/#/home/account/information/">

                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="27" viewBox="0 0 21 27" fill="none">
                    <mask id="mask0_10_60" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="16" width="21" height="11">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.333374 16.7241H20.7932V26.2489H0.333374V16.7241Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_10_60)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5645 18.6616C5.06075 18.6616 2.27075 19.6071 2.27075 21.4736C2.27075 23.3568 5.06075 24.3114 10.5645 24.3114C16.067 24.3114 18.8558 23.3659 18.8558 21.4994C18.8558 19.6162 16.067 18.6616 10.5645 18.6616ZM10.5645 26.2489C8.03417 26.2489 0.333252 26.2489 0.333252 21.4736C0.333252 17.2162 6.17288 16.7241 10.5645 16.7241C13.0949 16.7241 20.7933 16.7241 20.7933 21.4994C20.7933 25.7567 14.9549 26.2489 10.5645 26.2489Z" fill="#3D3B36"/>
                    </g>
                    <mask id="mask1_10_60" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="3" y="0" width="15" height="15">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.70447 0.583466H17.422V14.2992H3.70447V0.583466Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask1_10_60)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5645 2.42745C7.79909 2.42745 5.54901 4.67624 5.54901 7.4417C5.53997 10.1981 7.77326 12.4456 10.5271 12.4559L10.5645 13.3782V12.4559C13.3287 12.4559 15.5775 10.2059 15.5775 7.4417C15.5775 4.67624 13.3287 2.42745 10.5645 2.42745ZM10.5646 14.2992H10.5232C6.74897 14.2875 3.69159 11.2095 3.70451 7.43782C3.70451 3.6597 6.78126 0.582947 10.5646 0.582947C14.3466 0.582947 17.422 3.6597 17.422 7.4417C17.422 11.2237 14.3466 14.2992 10.5646 14.2992Z" fill="#3D3B36"/>
                    </g>
                    </svg>
                </a>
            </div>

        </div>
        <div class="nav_icons log-in-btn" fxLayoutAlign="center"  fxLayout="row">
            <button *ngIf="!warehouselogo" mat-raised-button routerLink="/sign-in" >Login</button>
        </div>
    </mat-sidenav>

    <mat-toolbar-row fxLayoutAlign="center center" class=" theme-container welcomtext">
        <span fxHide="false" fxHide.gt-md >
            <button mat-button (click)="sidenav.toggle()" class="sidenav-toggle">
                <mat-icon>menu</mat-icon>
            </button>
        </span>
        <span  fxLayoutAlign="center center">
            <a *ngIf="userLogged" class="user" (click)="closeSubMenus()" style="font-family: 'Lato';font-size: small; font-weight: 600; letter-spacing: 2px">Welcome {{ retailername }}</a>

            <a *ngIf="!userLogged" class="user" (click)="closeSubMenus()" style="font-family: 'Lato';font-size: small; font-weight: 600; letter-spacing: 2px">Welcome Guest</a>
        </span>
        <span fxHide >{{ 'WELCOME' | translate }}</span>
        <app-top-menu></app-top-menu>
    </mat-toolbar-row>
    <mat-toolbar  color="primary" id="header-toolbar" [hidden]="layout != 'classic'">
        <mat-toolbar-row fxLayoutAlign="space-between center" class="top-toolbar theme-container">
            <span fxHide="false" fxHide.gt-md >
                <button mat-button (click)="sidenav.toggle()" class="sidenav-toggle">
                    <mat-icon>menu</mat-icon>
                </button>
            </span>
            <span fxHide fxLayoutAlign="center center"><mat-icon class="mat-icon-sm">call</mat-icon>  Hotline: (+100) 123 456 7890 </span>
            <span fxHide >{{ 'WELCOME' | translate }}</span>
            <app-top-menu></app-top-menu>
        </mat-toolbar-row>
        <mat-toolbar-row fxLayoutAlign="space-between center" class="logo-toolbar theme-container">
            <div fxLayout="row" class="warehouse-tag" fxLayout.md="column" fxFlex="15">
                <div class="logo-warehouse logowithtitle" fxLayout="column" >
                    <img [src]='warehouselogo' alt=""  onerror="this.src='/assets/images/others/users.jpg'"  style="max-width:100px;height:100px;" text-align= "left"
                    onerror="this.src='/assets/images/others/user.jpg'">
                </div>
                <div class="warehouse-det" fxLayout="column" fxLayoutAlign="center center">
                    <div fxLayout="row" class="logowithtitle">
                        <a *ngIf="!warehousename" class="logo"  (click)="closeSubMenus()">STORiMART</a>
                        <a *ngIf="warehousename" class="warehousetitle" >{{ warehousename }}</a>
                    </div>
                </div>
            </div>
            <div fxFlex="70" fxShow="false" fxShow.gt-sm fxLayoutAlign="center">
                <form method="get" class="search-form" fxLayout="row">
                    <input type="text" placeholder="Search Products" name="search" #search  [formControl]="typeahead" class="sb-form searchboxheader" matInput
                    [matAutocomplete]="auto" aria-label="Site search"  (keyup.enter)="goToSearchResults(search.value)" type="text"  fxFlex>
                    <mat-autocomplete class="filterdproductSuggestion" #auto="matAutocomplete" (optionSelected)='goToSearchResults(search.value)'>
                        <mat-option *ngFor="let option of filteredProduct" [value]="option" style="color: rgb(94, 93, 93);">
                          {{option}}
                        </mat-option>
                      </mat-autocomplete>
                    <button mat-mini-fab  type="button" (click)="goToSearchResults(search.value)" class="search-btn mat-elevation-z0 text-muted">
                        <mat-icon>search</mat-icon>
                    </button>
                </form>
            </div>
            <div fxFlex="15" fxLayout="row" fxLayoutAlign="end center">
                <div fxLayoutAlign="center center" [@.disabled]="true">
                        <div class="nav_icons burgericon"  [routerLink]="['/home/wishlist']" *ngIf="userLogged">
                            <div style="display: flex;">
                                <mat-icon style="color: gray;" class="favoriteicon" matTooltip="Show wishlist" >favorite</mat-icon>
                           </div>
                            <div class="badge-wislist" *ngIf="wishlistItems.length>0">
                                <span class="badge-count-wislist badge-count" >{{wishlistItems.length}}</span>
                            </div>
                        </div>


                        <div class="nav_icons" fxLayout="row" [matMenuTriggerFor]="cartMenu" #cartMenuTrigger="matMenuTrigger"  *ngIf="userLogged">
                            <div class="cursor" style="display: flex;">
                                <mat-icon style="color: gray;" matTooltip="Cart details">shopping_cart</mat-icon>
                            </div>
                            <div class="badge-cart"  *ngIf="cartItems.length>0">
                                <span class="badge-count-cart" style="padding: 4px;">{{cartItems.length}}</span>
                            </div>
                        </div>
                        <div class="nav_icons  " fxLayout="row">
                            <mat-icon  matTooltip="My Account" [matMenuTriggerFor]="accountMenu" #accountMenuTrigger="matMenuTrigger" routerLinkActive="router-link-active" style="color: gray;" fxHide>account_circle</mat-icon>

                            <button mat-stroked-button [routerLink]="['/sign-in']" color="primary" matTooltip="Login"  *ngIf="!userLogged">Log In</button>
                        </div>

                    <mat-menu #cartMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="top-menu-dropdown cart-dropdown">
                        <ng-template matMenuContent>
                            <span class="d-block">
                                <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted">
                                    <b><span>{{cartItems.length}} ITEM<span *ngIf="cartItems.length > 1">S</span></span></b>
                                    <b><a [routerLink]="['/home/cart']" class="text-muted">VIEW CART</a></b>
                                </div>
                                <div class="divider mt-1"></div>
                                <div *ngIf="cartItems.length == 0" class="py-1 text-muted text-center">You have no items in your shopping cart.</div>
                                <mat-list>

                                    <mat-list-item *ngFor="let product of cartItems">
                                        <h3 matLine class="text-muted" style="cursor:pointer" (click)="navigateProductDetail(product.productId,product.productShortName,product.skuId)"> {{product.productLongName}}</h3>
                                        <p matLine class="text-muted">
                                            <bdi>{{product.quantity}} x {{product.price.currency}} {{product.price.offer??product.price.actual| number : '1.2-2'}}</bdi>
                                        </p>
                                        <img  [src]="product.productImageUrl" alt="image" class="cartpoupimg" (click)="navigateProductDetail(product.productId,product.productShortName,product.skuId)" style="cursor:pointer">
                                        <button mat-icon-button color="warn" class="remove" (click)="remove(product);stopClickPropagate($event)" matTooltip="Remove" matTooltipPosition="before">
                                            <mat-icon class="mat-icon-sm">close</mat-icon>
                                        </button>
                                    </mat-list-item>
                                </mat-list>
                                <div *ngIf="cartItems.length > 0" class="cart-dropdown-footer">
                                    <div class="divider mt-1"></div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted mt-1">
                                        <b>Sub Total:</b>
                                        <b class="new-price">{{currency}} {{cartTotalprice | number : '1.2-2'}}</b>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted">
                                        <b>Tax:</b>
                                        <b class="new-price"> {{currency}} {{totalTax | number : '1.2-2'}}</b>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted">
                                        <b>Total:</b>
                                        <b class="new-price">{{currency}} {{cartTotalprice+totalTax | number : '1.2-2'}}</b>
                                    </div>
                                    <div class="divider mt-1"></div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-1">
                                        <button mat-mini-fab color="warn" (click)="clear();stopClickPropagate($event)" matTooltip="Clear All" matTooltipPosition="after">
                                            <mat-icon>remove_shopping_cart</mat-icon>
                                        </button>
                                        <a [routerLink]="['/home/checkout']" mat-mini-fab color="primary" matTooltip="Checkout" matTooltipPosition="before">
                                            <mat-icon>check</mat-icon>
                                        </a>
                                    </div>
                                </div>
                            </span>
                        </ng-template>
                    </mat-menu>


                    <mat-menu #accountMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown account">
                        <span (mouseleave)="accountMenuTrigger.closeMenu()">
                            <div fxLayout="row" fxLayoutAlign="space-around center" class="user-info">
                                <img [src]='warehouselogo' alt=""  onerror="this.src='/assets/images/others/users.jpg'"  style="max-width:100px;height:100px;" text-align= "left"
                                onerror="this.src='/assets/images/others/user.jpg'">
                                <p>{{warehousename}} <br> <small>{{retailername}}</small></p>
                            </div>
                            <div class="divider"></div>
                            <a mat-menu-item routerLink="/home/account/information" *ngIf="userLogged">
                                <mat-icon class="mat-icon-sm">settings</mat-icon>
                                <span>Profile</span>
                            </a>
                            <a mat-menu-item routerLink="/home/account/orders" *ngIf="userLogged">
                                <mat-icon class="mat-icon-sm">shopping_cart</mat-icon>
                                <span>Order history <span *ngIf="settings.rtl">&rlm;</span>({{cartCount}})</span>
                            </a>
                            <a mat-menu-item routerLink="/home/wishlist" *ngIf="userLogged">
                                <mat-icon class="mat-icon-sm">favorite</mat-icon>
                                <span>Wishlist <span *ngIf="settings.rtl">&rlm;</span>({{wishlistCount}})</span>
                            </a>

                            <div class="divider"></div>
                            <a mat-menu-item *ngIf="!userLogged" routerLink="/sign-in">
                                <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
                                <span>Login</span>
                            </a>
                            <a mat-menu-item *ngIf="userLogged" routerLink="/" (click)="logout()">
                              <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
                              <span>Logout</span>
                          </a>
                        </span>
                    </mat-menu>
                </div>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>



    <app-menu [shopid]="wareHouseStoreId" [retailShopId]="shopId" fxShow="false" fxShow.gt-sm></app-menu>


    <div class="theme-container main">
        <div>
            <!-- <app-breadcrumb></app-breadcrumb> -->
            <router-outlet></router-outlet>
          </div>
    </div>


    <app-options></app-options>

    <app-footer></app-footer>



    <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition" (click)="scrollToTop()">
        <mat-icon>arrow_upward</mat-icon>
    </div>


 </mat-sidenav-container>
