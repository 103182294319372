<div class="skeletonLoader" *ngIf="loading" style="max-height: 332px;">
    <ngx-skeleton-loader count="2" appearance="line"></ngx-skeleton-loader>
</div>
<h5 mat-dialog-title style="    font-size: medium;"><span *ngIf="items">You have <span>{{items.length}}</span> items in
        your cart</span></h5>
<mat-dialog-content class="mat-typography" *ngIf="!loading">
    <div style="    max-height: 300px;">

        <div>
            <div *ngFor="let item of items" style="margin-bottom: 10px;">
                <div style="display: flex;
                    justify-content: space-around;">
                    <div *ngIf="displayImage == true">
                        <img style="height: 65px;
                            padding: 5px;
                            border-radius: 10px;cursor: pointer;" *ngIf="!item.productImageUrl.includes('no_Img')" [src]="item.productImageUrl" alt="" (click)="navigateProductDetail(item.productId,item.productShortName,item.skuId)">
                         <ngx-avatar initialsSize="3" *ngIf="item.productImageUrl.includes('no_Img')" name="{{item.productShortName}}" initialsSize="3" textSizeRatio="5" [size]="65"
                        [round]="false" cornerRadius="5" (click)="navigateProductDetail(item.productId,item.productShortName,item.skuId)"></ngx-avatar>

                    </div>
                    <div *ngIf="!displayImage == true">
                         <ngx-avatar initialsSize="3" name="{{item.productShortName}}" initialsSize="3" textSizeRatio="5" [size]="60"
                        [round]="false" cornerRadius="5" (click)="navigateProductDetail(item.productId,item.productShortName,item.skuId)"></ngx-avatar>

                    </div>
                    <div style="display: flex;
                    flex-direction: column;
                    justify-content: center;
                    font-size: small;
                    font-weight: 600;
                    color: #444141;
                    margin-left: 5px;"(click)="navigateProductDetail(item.productId,item.productShortName,item.skuId)">
                        <span (click)="navigateProductDetail(item.productId,item.productShortName,item.skuId)">{{item.productShortName}}nnn</span>
                        <span>Qty:&nbsp;{{item.quantity}}</span>
                        <span *ngIf="!openShop">Price:&nbsp;<span style="color:blueviolet">{{item.price.offer??item.price.actual|currency:item.price.currency}}</span></span>
                    </div>
                    <div>
                        <button mat-button color="warn" (click)="removeProduct(item.skuId)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
                <mat-divider>
                </mat-divider>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div style="display: flex;flex-direction:column;">
        <div *ngIf="!openShop" style="margin-bottom: 20px;
        display: flex;
        color: darkslategray;
        font-size: 15px;
        font-weight: 500;
        justify-content: space-between;">
            <span>Sub Total:</span>
            <span>{{subtotal|currency:items?.[0]?.price.currency}}</span>
        </div>
        <div style="margin-bottom: 10px;
        display: flex;
        justify-content: space-between;">
            <button mat-stroked-button color="primary" [disabled]="loading||items?.length==0" (click)="NavigateToCheckout()">Proceed to
                checkout</button>
            <button mat-stroked-button [disabled]="loading||items?.length===0" (click)="NavigateToCart()">View cart</button>
        </div>
    </div>


</mat-dialog-actions>
