<footer class="footer">
    <div fxLayout="column" class="py-3 footer-data" fxlLayoutAlign="center center">
        <div fxFlex="40"class="footer-data" fxLayout="row" fxFlex.gt-sm="12" fxFlex.sm="40">
            <h3 class="col-title">Information :</h3>
            <li class="mt-21"><a routerLink="/home/contact"  class="link font-color" style="color: black; font:bold;">About Us</a></li>
            <li class="mt-11"><a href="#"  class="link font-color" style="color: black; font:bold;pointer-events: none">Features</a></li>
            <li class="mt-11"><a href="#" class="link font-color" style="color: black; font:bold;pointer-events: none">Download</a></li>
            <li class="mt-11"><a href="#"  class="link font-color" style="color: black; font:bold;pointer-events: none">Privacy Policy</a></li>
            <li class="mt-11"><a href="#"  class="link font-color" style="color: black; font:bold;pointer-events: none">Contact Us</a></li>
            <li class="mt-11"><a href="#" class="link font-color" style="color: black; font:bold;pointer-events: none">Terms of Use</a></li>
        </div>
        <div fxFlex="40" class="footer-data" fxLayout="row" fxFlex.gt-sm="12" fxFlex.sm="40" >
            <h3 class="col-title">My Account :</h3>
            <li class="mt-21"><a routerLink="account/orders" class="link font-color" style="color: black; font:bold;">Order History</a></li>
            <li class="mt-11"><a routerLink="wishlist" class="link font-color" style="color: black; font:bold;">Wishlist</a></li>
            <li class="mt-11"><a routerLink="." class="link font-color" style="color: black; font:bold;" (click)="goToOffers()">Offers</a></li>
        </div>
        <div fxFlex="40" class="footer-data" fxLayout="row" fxFlex.gt-sm="12" fxFlex.sm="40" *ngIf="facebookUrl !='' || instagramUrl !=''||youtubeUrl !='' || twitterUrl !='' || linkedinUrl !='' ">
            <h3 class="col-title">Social</h3>
            <li class="mt-21" *ngIf="facebookUrl !=''"><a [href]="facebookUrl" target="_blank" class="link font-color" style="color: black; font:bold;">Facebook</a></li>
            <li class="mt-11" *ngIf="instagramUrl !=''"><a [href]="instagramUrl" target="_blank"class="link font-color" style="color: black; font:bold;">Instagram</a></li>
            <li class="mt-11"  *ngIf="youtubeUrl !=''"><a [href]="youtubeUrl"  target="_blank" class="link font-color" style="color: black; font:bold;">Youtube</a></li>
            <li class="mt-11"  *ngIf="twitterUrl !=''"><a [href]="twitterUrl" target="_blank" class="link font-color" style="color: black; font:bold;">Twitter</a></li>
            <li class="mt-11"  *ngIf="linkedinUrl !=''"><a [href]="linkedinUrl" target="_blank" class="link font-color" style="color: black; font:bold;">LinkedIn</a></li>
          </div>
        <div fxFlex="40" fxFlex.gt-sm="12" fxFlex.sm="40" class="contact-us" fxHide>
            <h3 class="col-title">Contact Us</h3>
            <p>{{shopAddress?.address}}</p>
        </div>

    </div>
    <div class="divider">
        <hr style="height:1px;border-width:0;color:gray;background-color:gray">
    </div>
    <div fxLayout="row wrap"class="company-name" fxLayout.sm="column" style="padding-left:4% ;margin-top:4%;" fxHide>
      <div fxFlex="30" fxLayoutAlign="center" class="about-us" fxFlex.lt-sm="100" fxLayoutAlign.lt-sm="center" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center">
        <div fxLayout="column" fxLayoutAlign="start" fxLayoutAlign.lt-sm="center" *ngIf="aboutUs!=''">
          <h6>About Us</h6>
          <p>{{aboutUs}}</p>
        </div>
      </div>
      <div fxFlex="40"  class="fot-logo" fxLayoutAlign="center" fxFlex.lt-sm="100" fxFlex.lt-md="100" fxLayoutAlign.lt-sm="center" fxLayoutAlign.lt-md="center">
        <a class="logo" routerLink="/" style="font-family: Lato; letter-spacing: 2px;"><img [src]="logo" width="100" height="80" class="wh-logo">
           <ngx-avatar initialsSize="3" *ngIf="logo == ''" name={{warehouseName}} value="28%" initialsSize="3" bgColor="#000000" fgColor="#58D3F7" size="60"
                textSizeRatio="2.5" [round]="true"></ngx-avatar>
        </a>
      </div>
      <div fxFlex="30" fxLayoutAlign="end" class="mail-us" fxFlex.lt-sm="100" fxLayoutAlign.lt-sm="center" fxFlex.lt-md="100" fxLayoutAlign.lt-md="center" *ngIf="shopAddress?.address" style="padding-right:5%">
        <div fxLayout="column" fxLayoutAlign.lt-sm="center" fxLayoutAlign="center">
          <h6>Contact Us</h6>
          <p>{{shopAddress?.address}}</p>
        </div>
      </div>
    </div>
</footer>

