import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {
  @Input('banners') banners: Array<any> = [];

  constructor(private router:Router) { }

  ngOnInit() { }

  public getBanner(index){
    // console.log('sub sub sub', this.banners[index])
    return this.banners[index];
    

  }
  // public getSubCat(index){
  //   let subCat = {
  //     'subCat':index != null ? this.banners[index].subcat : null
  //   };
  //   console.log('sub sub sub', subCat)
  //   return subCat
  // }
  public getBgImage(index){
    let bgImage = {
      'background-image': index != null ? "url(" + this.banners[index].image + ")" : "url(https://via.placeholder.com/600x400/ff0000/fff/)"
    };
    return bgImage;
  } 

    /**
   * Navigate to Product Listingpage
   */
    showProducts(category) {
      if(category.subcat && category.subcat.length>0){
        this.router.navigate(['/home/products'],{queryParams:{category:category.subcat[0].categoryId}})
      }
      else{
        this.router.navigate(['/home/products'],{queryParams:{category:category.id}})
      }
    }
    /**
   * Category Dropdown Button 
   */
  openMegaMenu(){
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
        if(el.children.length > 0){
          if(el.children[0].classList.contains('sub-cat-menu')){
            el.classList.add('mega-subcat-pane');
          }
        }
    });
  }
  

}
