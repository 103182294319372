import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { AllCatagory } from "src/app/services/models/product.model"
import { environment } from "src/environments/environment"
import { removefromcartPayload, removefromcartResponse } from "./interface"

@Injectable({
    providedIn: 'root'
  })
  export class HeaderService {
  
    private APIEndpoint = environment.serviceUrl
  
    constructor(private http: HttpClient) { 
    
    }
  
    getCategories(shopId:string,retailShopId:string){
        return this.http.get<AllCatagory>(`${this.APIEndpoint}catalog?shopId=${shopId}&retailShopId=${retailShopId}&searchType=ALLCATSONLY`)
    }

    removeProductFromCart(payload:removefromcartPayload){
      return this.http.post<removefromcartResponse>(`${this.APIEndpoint}removefromcart/new`,payload)
    }
  
  }
  