import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class GlobalStateService {

  private _globalData: any = {
    "address":{},
    "paymentModes": [],
    "shops": [],
    "status": null,
    "userInfo": null,
    "warehouses": [],
    "cartdetails": { order: [{
      items:{
        item:[]
      }
    }] },
    "wishlistdetails": { order: [{
      items:{
        item:[]
      }
    }] },
    "openShop": false,
    "userLogged":false,
    "banner":[],
    "category": [
      { "title": "Category 1", "subtitle": "Description", "image": "assets/images/carousel/placeholder-banner.png", id: null,subcat:[] },
      { "title": "Category 2", "subtitle": "Description", "image": "assets/images/carousel/placeholder-banner.png", id: null,subcat:[] },
      { "title": "Category 3", "subtitle": "Description", "image": "assets/images/carousel/placeholder-banner.png", id: null,subcat:[] },
      { "title": "Category 4", "subtitle": "Description", "image": "assets/images/carousel/placeholder-banner.png", id: null,subcat:[] },
      { "title": "Category 5", "subtitle": "Description", "image": "assets/images/carousel/placeholder-banner.png", id: null,subcat:[] },
      { "title": "Category 6", "subtitle": "Description", "image": "assets/images/carousel/placeholder-banner.png", id: null,subcat:[] }]
  };

  get globalData(): GlobalState {
    if(localStorage.getItem('_globalData')){
      return JSON.parse(localStorage.getItem('_globalData'))
    }
    else{
      return this._globalData
    }
  }

  updateGlobalData(data: any) {
    if(localStorage.getItem('_globalData')){
      this._globalData = { ...JSON.parse(localStorage.getItem('_globalData')), ...data };
    }
    else{
      this._globalData = { ...this._globalData, ...data };
    }
    localStorage.setItem('_globalData',JSON.stringify(this._globalData))
  }

}

export interface GlobalState{
  address:address
  paymentModes:paymentModes[];
  shops:shops[];
  status:status;
  userInfo:any;
  warehouses:warehouse[];
  cartdetails:cartdetails;
  wishlistdetails:cartdetails;
  openShop:boolean;
  userLogged:boolean;
  banner:Banner[];
  category:Category[]
}

interface address{
  adLine1: string;
  adLine2: string;
  addressId: string;
  city: string;
  country: string;
  firstName: string;
  lastName: string;
  state: string;
  street: string;
  userEmail: string;
  userPhone: string;
  zip: string;
}

interface Banner{
  title:string;
  subtitle:string;
  image:string
}

interface Category{
  title:string;
  subtitle:string;
  image:string;
  id:string|null;
}


interface paymentModes {
  paymentModeId: string;
  paymentModeName: string;
  paymentModeDescription: string;
}

interface shops{
  shopName: string;
  shopId: string;
  orderSendToPhone?: any;
  latitude?: any;
  longitude?: any;
  minOrderAmount?: any;
  openingTime?: any;
  closingTime?: any;
  minTimeToDeliver?: any;
  cancelCutOffStatus?: any;
  imgUrlSmall?: any;
  imgUrlMedium?: any;
  imgUrlLarge?: any;
}

interface status{
  success: boolean;
  userId: string;
  status: string;
  message?: any;
  otp: string;
  orderId?: any;
  creditStatusEnabled: string;
  creditStatusUrlForErp?: any;
  creditBalance?: any;
  creditLimit?: any;
  warehouses: Warehouse[];
}

 interface Address {
  adLine1: string;
  adLine2?: any;
  street: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  email?: any;
  phone?: any;
  addressId: string;
}

 interface Warehouse {
  wareHouseStoreId: string;
  displayProdImages: boolean;
  warehouseLogo: string;
  warehouseName: string;
  tenantId: string;
  shippingCost: number;
  applyTax: boolean;
  enableInventory: boolean;
  enableInventoryCountForSalesman?: any;
  enableOfflineSalesmanApp?: any;
  enablePaymentGateway: boolean;
  enableOpenShop: boolean;
  address: Address;
}

interface warehouse {
  wareHouseStoreId: string;
  displayProdImages: boolean;
  warehouseLogo: string;
  warehouseName: string;
  tenantId: string;
  shippingCost: number;
  applyTax: boolean;
  enableInventory: boolean;
  enableInventoryCountForSalesman?: any;
  enableOfflineSalesmanApp?: any;
  enablePaymentGateway: boolean;
  enableOpenShop: boolean;
  address: Address;
}

interface cartdetails {
    order: orders[];
}

interface orders {
  erpEnabled: string;
  creditStatusEnabled: string;
  orderUrlForErp: string;
  notes: any[];
  outstandingAmount: number;
  payments: any[];
  finacialStatus: string;
  netShippingCost: number;
  creditCheck: any;
  shipMethod: string;
  paymentMethod: string;
  header: Header;
  accountBalance: number;
  paymentDetails: PaymentDetails;
  userNotes?: any;
  paidAmount: number;
  items: Items;
  creditStatusUrlForErp?: any;
}

interface Header {
  shipToAddress: ShipToAddress;
  orderType: string;
  shippingCost: string;
  orderId: string;
  grandTotal: string;
  orderDisplayName: string;
  retailerShop: RetailerShop;
  discountAmount: string;
  orderedDate: string;
  warehouse: Warehouse;
  cancellable: boolean;
  netTaxAmount: string;
  totalAmount: TotalAmount;
  action: string;
  ordersUpto: string;
  user: User;
  status: Status;
}

interface ShipToAddress {
  zip: string;
  country: string;
  adNameFirst: string;
  city: string;
  phone?: any;
  street?: any;
  adLine2?: any;
  adLine1: string;
  state: string;
  adNameLast: string;
  addressId: string;
}
interface RetailerShop {
  phone: string;
  retailerShopId: string;
  retailerShopName: string;
}

interface TotalAmount {
  currency: string;
  value: number;
}

interface User {
  phone: string;
  userName: string;
  userId: string;
}

interface Status {
  message: string;
  value: string;
}

interface PaymentDetails {
  payments: any[];
}

interface Items {
  item: Item[];
}

interface Item {
  skuMfrPartNum: string;
  productImageUrl: string;
  productLongName: string;
  isAvailable: boolean;
  quantity: number;
  productId: string;
  orderItemId: string;
  shopProductId: string;
  productErpId: string;
  isminimumquantitychanged: boolean;
  skuShopId: string;
  skuErpId: string;
  productShortName: string;
  productMfrPartnum: string;
  uom: string;
  minimumquantity: string;
  productIconUrl: string;
  price: Price;
  action: string;
  stock: Stock;
  skuId: string;
  status: Status2;
  isFavorite: boolean;
}

interface Price {
  offer?: number;
  actual: number;
  isPricechanged: boolean;
  taxIncluded: boolean;
  oldprice: number;
  taxPercent: number;
  currency: string;
  tax: number;
}

interface Stock {
  value: string;
}

interface Status2 {
  message: string;
  value: string;
}