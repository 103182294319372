import { Component, OnInit, HostListener, ViewChild, Inject, PLATFORM_ID, Input } from '@angular/core';
import { Router, NavigationEnd, ChildrenOutletContexts } from '@angular/router';
import { Settings, AppSettings } from 'src/app/app.settings';
import { AppService } from 'src/app/app.service';
import { Category } from 'src/app/app.models';
import { SidenavMenuService } from '../../theme/components/sidenav-menu/sidenav-menu.service';
import { isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { UserDataModel } from '../interfaces/login';
import { LoginService } from '../../sign-in/login.service';
import { DepartmentsService } from '../service/departments.service';
import { FormControl } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FadeInAnimation } from '../animations/animation';
import { MessageService } from '../../theme/utils/messageService';
import { CartDialogComponent } from '../../cart/cart-dialog/cart-dialog.component';
import { CommonServices } from 'src/app/services/common.service';
import { GlobalStateService } from '../service/global-state.service';
import { HeaderService } from './header.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Item } from './interface';
import { item } from 'src/app/services/models/cartitems.model';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [SidenavMenuService],
  animations: [
    FadeInAnimation
  ]
})
export class HeaderComponent implements OnInit {

  public showBackToTop: boolean = false;
  debouncedInput$ = new Subject<string>();
  public sidenavMenuItems: Array<any>;
  @ViewChild('sidenav', { static: true }) sidenav: any;
  @ViewChild('search') search:any;
  @Input() layout: 'classic' | 'compact' = 'classic';
  warehouselogo: string;
  warehousename: string;
  retailername: string;
  userLogged:boolean;
  openShop: boolean;
  catalogPreview: boolean = false;
  public settings: Settings;
  cartCount: number = 0;
  wishlistCount: number = 0;
  subscription: Subscription
  getCartDetails : any
  getWishlistDetails : any
  wareHouseStoreId: string;
  shopId: string;
  cartItems: Array<any> = []
  wishlistItems:Array<any> = []
  totalTax: number;
  currency: any = ''
  constructor(private loginService: LoginService,
    public appSettings: AppSettings,
    public appService: AppService,
    public headerService:HeaderService,
    public sidenavMenuService: SidenavMenuService,
    public router: Router,
    private matDialog: MatDialog,
    private departmentsService: DepartmentsService,
    private contexts: ChildrenOutletContexts,
    private messageService: MessageService,
    private commonService: CommonServices,
    public snackBar: MatSnackBar,
    private global: GlobalStateService,
    @Inject(PLATFORM_ID) private platformId: Object) {

    let userDatamodel: UserDataModel = {};
    userDatamodel= JSON.parse(sessionStorage.getItem('userData'))

    this.settings = this.appSettings.settings;
  }

  public typeahead: FormControl = new FormControl();
  suggestions: string[] = [];
  products: string[] = [];
  filteredProduct = []


  ngOnInit() {
    this.headerConfigInit()
    this.loadProductListForMenu()
    this.loadCategoryList()
    this.rxjsSubscribeWhislistCartcount()
  }

   /**
   * RXJS subscription for getting current wishlist and cart item counts
   */
   rxjsSubscribeWhislistCartcount() {
     this.subscription = this.messageService.getMessage().subscribe(message => {
      if(JSON.parse(message.text)?.cartdetails && JSON.parse(message.text)?.cartdetails.order.length){
        this.cartItems= JSON.parse(message.text)?.cartdetails.order[0].items.item
        this.currency = this.cartItems.length?this.cartItems[0].price.currency:''
        this.totalTax = Number(JSON.parse(message.text)?.cartdetails.order[0].header?.netTaxAmount)
      }
      else if(JSON.parse(message.text)?.wishlistdetails && JSON.parse(message.text)?.wishlistdetails.order.length){
        this.wishlistItems=JSON.parse(message.text)?.wishlistdetails.order[0].items.item
      }
    });
  }


  /**
   * Delaying keyup event while we type, calling BE calls only after stops typing
   */
  debounceEventSubscribe() {
    fromEvent(this.search.nativeElement, 'keyup').pipe(debounceTime(100))
    .subscribe(() => {
      this.filteredProduct = this.products.filter(option => option.toLowerCase().includes(this.search.nativeElement.value))
      this.debouncedInput$.next(this.search.nativeElement.value)
    });

  }

  loadCategoryList() {
    if(localStorage.getItem('AuthorizationToken')){
      this.wareHouseStoreId =  this.global.globalData.status?.warehouses[0].wareHouseStoreId
      this.shopId = this.global.globalData.shops[0]?.shopId
      this.headerService.getCategories(this.wareHouseStoreId,this.shopId).subscribe({
        next:(response)=>{
          if(response.acknowledgment.status=="SUCCESS"){

          }
          else{
            this.snackBar.open('Fetching Categories list failed!','Close',{ panelClass: 'error', verticalPosition: 'bottom', duration: 3000 })
          }
        }
      })
    }

  }

  /**
   * Getting all Products from BE for search suggestions
   */
  loadProductListForMenu() {
    if(localStorage.getItem('AuthorizationToken')){
      let wareHouseStoreId =  this.global.globalData.status?.warehouses[0].wareHouseStoreId
      let shopId = this.global.globalData?.shops[0].shopId
      this.departmentsService.getproductNames(wareHouseStoreId,shopId).subscribe(response => {
        if (response.status === "200") {
          this.products = response.productNames;
        }
      });
    }
  }

  /**
   * Getting Logo,title,wishlistCount and cart count from GlobalState and apply to the header elements
   */
  headerConfigInit() {
    if(this.global.globalData.status){
      this.openShop = this.global.globalData.openShop
      this.userLogged = this.global.globalData.userLogged
      let warehouse =  this.global.globalData.status?.warehouses[0]
      this.warehouselogo = warehouse?.warehouseLogo
      this.warehousename = warehouse?.warehouseName;
      this.retailername = this.global.globalData.address?.firstName + ' ' + this.global.globalData.address?.lastName;
      this.wishlistItems = this.global.globalData.wishlistdetails?.order?.length?this.global.globalData.wishlistdetails.order[0].items.item:[]
      this.cartItems = this.global.globalData.cartdetails?.order?.length?this.global.globalData.cartdetails.order[0].items.item:[]
      this.currency = this.cartItems.length?this.cartItems[0].price.currency:''
      this.totalTax = Number(this.global.globalData?.cartdetails.order[0]?.header?.netTaxAmount)
    }

  }

  get cartTotalprice(){
    let total = 0
    this.cartItems.forEach(e=>{
      if(e.price.offer){
        total = total+(e.price.offer*e.quantity)
      }
      else{
        total = total+(e.price.actual*e.quantity)
      }
    })
    return total
  }


  MyAccountDetails(){
    if(this.openShop){
      this.router.navigate(['/sign-in'])
    }
    else{
    }
  }


  goToSearchResults(value) {
    value = value.trim();
    this.typeahead.reset();
    this.router.navigate(["home/products"], { queryParams: { search: value } });
  }

  public remove(product:Item) {
    this.headerService.removeProductFromCart({skuId:product.skuId}).subscribe({
      next:(response)=>{
        if(response.order[0].header.status.value=='CART'){
          const index: number = this.cartItems.indexOf(product);
          this.cartItems.splice(index,1)
          this.global.updateGlobalData({cartdetails:response})
          this.messageService.sendMessage(JSON.stringify({cartdetails:response}))
          this.messageService.sendMessage(JSON.stringify({cartitemremoved:response}))
          this.snackBar.open(response.order[0].header.status.message,'Close',{ panelClass: 'success', verticalPosition: 'bottom', duration: 3000 })
        }
        else if(response.order[0].header.status.value=='FAILED'){
          this.snackBar.open(response.order[0].header.status.message,'Close',{ panelClass: 'error', verticalPosition: 'bottom', duration: 3000 })
        }
      },
      error:(err)=>{
        this.snackBar.open("Some thing went wrong!",'Close',{ panelClass: 'error', verticalPosition: 'bottom', duration: 3000 })
      }
    })
  }

  public clear() {
    this.cartItems.forEach(e=>{
      this.remove(e)
    })
  }


  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }




  public scrollToTop() {
    var scrollDuration = 200;
    var scrollStep = -window.pageYOffset / (scrollDuration / 20);
    var scrollInterval = setInterval(() => {
      if (window.pageYOffset != 0) {
        window.scrollBy(0, scrollStep);
      }
      else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      setTimeout(() => {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
      });
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
    let header_toolbar = document.getElementById('header-toolbar');
    if (header_toolbar) {
      if (scrollTop >= header_toolbar.clientHeight) {
        this.settings.mainToolbarFixed = true;
      }
      else {
        if (!document.documentElement.classList.contains('cdk-global-scrollblock')) {
          this.settings.mainToolbarFixed = false;
        }
      }
    }
    else {
      this.settings.mainToolbarFixed = true;
    }
    ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;
  }

  ngAfterViewInit() {
    this.debounceEventSubscribe()
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
      }
    });
    this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());
  }

  public closeSubMenus() {
    if (window.innerWidth < 960) {
      this.sidenavMenuService.closeAllSubMenus();
    }
  }

  cartPopup() {
    const dialogRef = this.matDialog.open(
      CartDialogComponent, {
      backdropClass: 'g-transparent-backdrop',
      width: '320px',
      height: 'auto',
      position: { right: '1%', top: '7%' },
      autoFocus:false,
      restoreFocus: false
    }

    );

  }
  openPopup() {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent,{
      autoFocus:false,
      restoreFocus: false
    })

  }
  //  if(matchMedia){
  //   const mobileMedia =window.matchMedia("(max-width:575px)")
  //   mobileMedia.addListener(this.WidthChange);
  //   this.WidthChange(mobileMedia);
  //  }
  //   WidthChange(mobileMedia){
  //     if(mobileMedia.matches){
  //           }
  //   }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  getCartCount() {
    this.commonService.getCartDetails().subscribe({
      next: (response) => {
        if (response?.order.length > 0)
          this.cartCount = response.order[0].items.item.length
        sessionStorage.setItem('cartdetails', JSON.stringify(response))
        this.getCartDetails= response.order[0].items.item
        this.global.updateGlobalData({"cartdetails":this.getCartDetails})
      }
    })
  }

  logout(){
    sessionStorage.clear()
    const globalData={"paymentModes": [],
    "shops": [],
    "status": null,
    "userInfo": null,
    "warehouses": [],
    "cartdetails": { order: [{
      items:{
        item:[]
      }
    }] },
    "wishlistdetails": { order: [{
      items:{
        item:[]
      }
    }] },
    "openShop": false,
    "userLogged":false
  };
    this.global.updateGlobalData(globalData)
    this.snackBar.open('You have been logged Out!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });

  }
  navigateProductDetail(id:number,name:string,skuId:string){
    console.log("here")
    this.router.navigate(['/home/products',id,name],{queryParams:{sku:skuId,id:id}})
  }
}
