import { Component } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, UrlSegment, NavigationEnd } from "@angular/router"; 
import { Title } from '@angular/platform-browser';
import { Settings, AppSettings } from '../../../app.settings';
import { SidenavMenuService } from '../../../theme/components/sidenav-menu/sidenav-menu.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  providers: [ SidenavMenuService ]
})
export class BreadcrumbComponent {

    public pageTitle:string;
    public breadcrumbs: {
        name: string;
        url: string
    }[] = [];

    public settings: Settings;
    constructor(public appSettings:AppSettings,
                public router: Router, 
                public activatedRoute: ActivatedRoute,                
                public title:Title,
                public sidenavMenuService:SidenavMenuService){
            this.settings = this.appSettings.settings; 
            this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.breadcrumbs = [];                
                this.parseRoute(this.router.routerState.snapshot.root);
            }
        })   
    }

    private parseRoute(node: ActivatedRouteSnapshot) { 
        if(node['_routerState'].url){
            let paths = node['_routerState'].url.split('/')
            this.breadcrumbs = []
            paths.forEach(e=>{
                if(e!='')
                this.breadcrumbs.push({
                    name:e.split('?')[0],
                    url:'/'+e
                })
            })  
        }
    }

    public closeSubMenus(){
        if(window.innerWidth < 960){
            this.sidenavMenuService.closeAllSubMenus();
        }    
    }

}