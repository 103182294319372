<div class="slideshow-container" >
  <div *ngFor="let slide of slides; let i = index;" >
    <div class="mySlides hide" [ngClass]="{'show': i==slideIndex }" >
      <!-- <div class="numbertext">1 /{{slides.length}}</div> -->
      <img [src]="slide.image" onerror="this.src='assets/images/carousel/placeholder-banner.png'" style="width:100%">
      <div class="text">{{slide.title}}</div>
    </div>
  </div>
 
 
  <a class="prev" (click)="plusSlides(-1)">❮</a>
  <a class="next" (click)="plusSlides(1)">❯</a>
  
  </div>
  <br>
  
  <div style="text-align:center">
    <span class="dot" [ngClass]="{'active': i==slideIndex}" (click)="currentSlide(i)" *ngFor="let  slide of slides;let i=index;"></span> 
  </div>