import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Address, PaymentMode, ResetOtpResponseModel, Shop, Status, UserDataModel, ValidateotpResponseModel, Warehouse } from '../shared/interfaces/login';
import { DOCUMENT } from '@angular/common';
import { OrderHistoryViewModel } from '../shared/interfaces/orderhistory';
import * as moment from 'moment';
import { OrderHistoryResponseModel } from '../shared/interfaces/orderhistory-response-model';
import { environment } from 'src/environments/environment';
import { Country, CountryResponseModel, TimeZone, TimeZoneResponseModel } from '../shared/interfaces/country-response-model';
import { CartItems } from 'src/app/services/models/cartitems.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalStateService } from '../shared/service/global-state.service';
import { openshopResponse } from './login.interfaces';

@Injectable({
	providedIn: 'root'
})
export class LoginService {
	orders: OrderHistoryViewModel[] = [];
	countries: Country[] = [];
	orderhistoryresponse: OrderHistoryResponseModel;
	userdatamodel: UserDataModel;
	errorMessage: string = "";
	token: string = "";
	user: UserDataModel = null
	warehouselogo: string = ""
	httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/json' },
		),
		observe: 'response' as 'response'
	};
	private APIEndpoint = environment.serviceUrl

	constructor(private http: HttpClient, private snackBar: MatSnackBar, @Inject(DOCUMENT) private document: HTMLDocument, private global: GlobalStateService) {
		this.orders = [];

	}

	/**
	 * Geting sellerdetails to show in Footer
	 * @returns
	 */
	getFooterDetails(user: UserDataModel) {
		let shopId: string = (user.warehouses[0].wareHouseStoreId).toString();
		let params = new HttpParams();
		params = params.set('shopId', shopId);
		return this.http.get(environment.serviceUrl + "sellerdetails", { params: params })
	}

  getAboutUs(shopId:any){
    let params = new HttpParams();
    params = params.set('shopId', shopId);
    return this.http.get(environment.serviceUrl + "sellerdetails", { params: params })
  }

	getCountryCodes() {
		return this.http.get<CountryResponseModel>(environment.serviceUrl + 'cntryphne');
	}

	getTimeZoneInfo() {
		return this.http.get<TimeZoneResponseModel>(environment.serviceUrl + 'timezone');
	}


	registerBuyer(registerBuyerForm) {
		return this.http.post<any>(environment.serviceUrl + 'openshop/retailer/add', registerBuyerForm)
	}

	resetOtp(loginform) {
		return this.http.post<ResetOtpResponseModel>(environment.serviceUrl + 'resetotp?phone=' + loginform.phone + '&email=' + loginform.email, '');
	}


	verifyOtp(otpValidate) {
		return this.http.post<any>(environment.serviceUrl + 'setpassword', otpValidate, this.httpOptions)
	}


	validateOtp(loginRequest) {
		return this.http.post<any>(environment.serviceUrl + 'user/login/password', loginRequest, this.httpOptions);
	}

  getCartDetails(){
    return this.http.get<CartItems>(environment.serviceUrl+'viewcart')
  }

  getWishlistItems(){
    return this.http.get<CartItems>(environment.serviceUrl+'favprodsbyuser')
  }

	tranformValidateotpResponseModelToUserDataModel(response: ValidateotpResponseModel): UserDataModel {

		let userDataModel: UserDataModel = {};
		{
			let address: Address = response.address;
			if (address) {
				userDataModel.userInfo = {

					firstName: address.firstName,
					lastName: address.lastName,
					adLine1: address.adLine1,
					adLine2: address.adLine2,
					street: address.street,
					city: address.city,
					state: address.state,
					country: address.country,
					zip: address.zip,
					userEmail: address.userEmail,
					userPhone: address.userPhone,
					addressId: address.addressId
				}
			}

			let status: Status = response.status;
			if (status) {
				userDataModel.status = status;
			}

			let warehouses: Warehouse[] = response.status.warehouses;
			if (warehouses) {
				userDataModel.warehouses = warehouses;
			}

			let paymentModes: PaymentMode[] = response.paymentModes;
			if (paymentModes) {
				userDataModel.paymentModes = paymentModes;
			}

			let shops: Shop[] = response.shops;
			if (shops) {
				userDataModel.shops = shops;
			}
			return userDataModel;
		}
	}


	public setLocalUser(userdatamodel: UserDataModel) {
		sessionStorage.setItem('userData', JSON.stringify(userdatamodel));
		this.user = JSON.parse(sessionStorage.getItem('userData'))
		this.global.updateGlobalData(userdatamodel)
		if (this.user) {
			this.warehouselogo = this.user.warehouses[0].warehouseLogo;
			// this.document.getElementById('appFavicon').setAttribute('href', this.warehouselogo);
		}

	}

	/**
	 * Check current domain, whether is openshop or not
	 * @param domainName string
	 * @returns Object
	 */
	checkDomainName(domainName: string) {
		return this.http.get<{ isOpenShop: Boolean }>(`${this.APIEndpoint}openshop-for-domain?domainName=${domainName}`)
	}

	checkOpenShop(){
		return this.http.post<openshopResponse>(`${this.APIEndpoint}login/openshop`,{})

		// return new Observable<Boolean>(observer => {
		// 	this.http.post<any>(environment.serviceUrl + 'login/openshop', {}, this.httpOptions).subscribe((data) => {
		// 		if (data.body.status.success) {
		// 			sessionStorage.setItem("AuthorizationToken", data.headers.get("Authorization"))
		// 			sessionStorage.setItem("openShopAuthorizationToken", data.headers.get("Authorization"))
		// 			let userDataModel: UserDataModel = {};
		// 			userDataModel = this.tranformValidateotpResponseModelToUserDataModel(data.body);
		// 			this.setLocalUser(userDataModel);
		// 			return observer.next(true);
		// 		}
		// 	})
			// .subscribe((response: HttpResponse<any>) => {

			// 	if (response.body.status.success) {

			// 		sessionStorage.setItem("AuthorizationToken",response.headers.get(environment.token))
			// 		sessionStorage.setItem("openShopAuthorizationToken",response.headers.get(environment.token))
			// 		let userDataModel: UserDataModel = {};
			// 				userDataModel = this.tranformValidateotpResponseModelToUserDataModel(response.body);
			// 				this.setLocalUser(userDataModel);
			// 				return observer.next(true); //Login successful
			// 	}
			// 	else
			// 	return observer.next(false); //Login unsuccessful
			// });

		// });
	}

	public getUserName(): string {
		let loggedinUSer: UserDataModel = this.getLocalUser();
		let userName = loggedinUSer.userInfo.firstName + " " + loggedinUSer.userInfo.lastName;
		return userName;
	}
	public getWareHouse(): string {
		let loggedinUSer: UserDataModel = this.getLocalUser();
		let warehouse = loggedinUSer.warehouses[0].warehouseName;
		return warehouse;
	}
	public getLocalUser(): UserDataModel {
		return JSON.parse(sessionStorage.getItem('userData'));
	}
	public getCartItems(): CartItems {
		return JSON.parse(sessionStorage.getItem('cartItems'));
	}
	public removeLocalUser() {
		sessionStorage.removeItem('userData');
		sessionStorage.removeItem('cartItems');
		sessionStorage.removeItem('wishlistItems');
		sessionStorage.removeItem('shippingInfo');
		sessionStorage.removeItem('"AuthorizationToken');
		sessionStorage.removeItem('openShop');
		sessionStorage.removeItem('category');


	}
	public removeCartItems() {
		sessionStorage.removeItem('cartItems');

	}
	public hasLocalUser(): boolean {
		return !!this.getLocalUser()
	}


	updateToken(token: string) {
		if (token && token.length) {
			localStorage.setItem('AuthorizationToken', token)
			localStorage.setItem('openShopToken', token)
		}
	}


	getOrderDetail(orderId) {
		this.orders = [];
		return new Observable<OrderHistoryViewModel[]>(observer => {
			this.http.get<OrderHistoryResponseModel>(environment.serviceUrl + 'ordersbyids?orderId=' + orderId)
				.subscribe((response: OrderHistoryResponseModel) => {
					response.order.forEach(order => {

						this.orders.push({
							totalCount: response.totalCount,
							status: order.header.status.value,
							orderId: order.header.orderId,
							orderDisplayName: order.header.orderDisplayName,
							notes: order.notes,
							user: order.header.user,
							orderedDate: moment(order.header.orderedDate, 'YYYY/MM/DD').format('DD MMM YYYY'),
							deliveryTime: moment(order.header.deliveryTime, 'YYYY/MM/DD').format('DD MMM YYYY'),
							totalAmount: parseFloat(order.header.totalAmount.value).toFixed(2),
							netTaxAmount: parseFloat(order.header.netTaxAmount).toFixed(2),
							grandTotal: parseFloat(order.header.grandTotal).toFixed(2),
							discountAmount: parseFloat(order.header.discountAmount).toFixed(2),
							currency: order.header.totalAmount.currency,
							paidAmount: order.paymentDetails.paidAmount,
							item: order.items.item,
							shippingCost: order.header.shippingCost,
							shipToAddress: order.header.shipToAddress,
							balance: ''
						})
					});
					observer.next(this.orders);
				},
					(error) => {
						observer.error()
					});
		});
	}

	getOrderHistory(index, offset, shopId) {
		return	this.http.get<OrderHistoryResponseModel>(environment.serviceUrl + 'ordersbyuser?offset=' + offset + '&limit=' + index + '&allOrders=true&retailerShopId=' + shopId)
		}

	searchOrderHistoryByDate(shopId,startDate, endDate, status) {
		return this.http.get<OrderHistoryResponseModel>(environment.serviceUrl + 'ordersbyuser?allOrders=true&retailerShopId=' + shopId + '&startDate=' + startDate + '&endDate=' + endDate + '&status=' + status)
	}

	searchOrderHistoryByStatus(status) {
		let user: UserDataModel = {};
		user = this.getLocalUser();
		this.orders = [];
		return new Observable<OrderHistoryViewModel[]>(observer => {
			this.http.get<OrderHistoryResponseModel>(environment.serviceUrl + 'ordersbyuser?allOrders=true&retailerShopId=' + user.shops[0].shopId + '&status=' + status)
				.subscribe((response: OrderHistoryResponseModel) => {
					response.order.forEach(order => {
						this.orders.push({
							totalCount: response.totalCount,
							status: order.header.status.value,
							orderId: order.header.orderId,
							orderDisplayName: order.header.orderDisplayName,
							notes: order.notes,
							user: order.header.user,
							orderedDate: moment(order.header.orderedDate, 'YYYY/MM/DD').format('DD MMM YYYY'),
							deliveryTime: moment(order.header.deliveryTime, 'YYYY/MM/DD').format('DD MMM YYYY'),
							totalAmount: parseFloat(order.header.totalAmount.value).toFixed(2),
							netTaxAmount: parseFloat(order.header.netTaxAmount).toFixed(2),
							grandTotal: parseFloat(order.header.grandTotal).toFixed(2),
							discountAmount: parseFloat(order.header.discountAmount).toFixed(2),
							currency: order.header.totalAmount.currency,
							paidAmount: order.paymentDetails.paidAmount,
							item: order.items.item,
							shippingCost: order.header.shippingCost,
							shipToAddress: order.header.shipToAddress,
							balance: ''
						})
					});
					observer.next(this.orders);
				},
					(error) => {
						observer.error()
					});
		});

	}

	searchOrderHistoryById(orderId) {
		return this.http.get<OrderHistoryResponseModel>(environment.serviceUrl + 'ordersbyids?orderId=' + orderId)
	}


}



